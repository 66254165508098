// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import Partners from '../../components/Shared/Partners';
//import images
import about from '../../images/secki/about.jpg';

class About extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light pt-0">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="6" md="6" xs="12">
                                <img src={about} className="img-fluid" alt="" style={{borderRadius: 5}} />
                            </Col>

                            <Col lg="6" md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4">Om oss</h4>
                                    <p className="text-muted" style={{fontSize: '16px', lineHeight: 1.8}}>Vi är en etablerad teknisk konsultfirma med specialkompetens inom mark- och infrastrukturprojekt. Med lång erfarenhet inom branschen erbjuder vi högkvalitativ kalkylering och rådgivningstjänster som stöder framgångsrika projektresultat. Utöver det levererar vi även pålitlig bygg- och fastighetsservice för att möta våra kunders behov på alla nivåer. Vår strategi bygger på samarbete och samverkan med våra kunder, där vi aktivt engagerar oss i deras projekt och strävar efter att bygga långsiktiga affärsrelationer.
                                        Sedan vår etablering på marknaden år 2018 har vi kontinuerligt levererat kvalitetstjänster och bidragit till våra kunders framgångar.
                                    </p>
                                    <Link to="/kontakta-oss" className="btn btn-primary mt-3">Kontakta oss</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default About;
