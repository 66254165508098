// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

///Import Components
import FeatureBoxSmall from "./feature-box-small";
import SectionTitleLeft from "../../components/Shared/section-title-left";

// Import images
import cameraImg from '../../images/icon/camera.svg';
import speedometer from '../../images/icon/speedometer.svg';
import lowBattery from '../../images/icon/low-battery.svg';
import game from '../../images/icon/game.svg';
import cameraSingle from '../../images/secki/ecki.jpeg';

class Feature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            features_lines : [
                { title : "Investeringskalkyler" },
                { title : "Exploateringskalkyler" },
                { title : "Ekonomisk utvärdering av arkitektförslag" },
                { title : "Anbudskalkyler" },
                { title : "Mängdförteckningar" },
                { title : "ÄTA-regleringar" },
                { title : "Rådgivning med nyckeltal" },
                { title : "Projektstöd/Controller" },
                { title : "Kostnadsstyrning" },
                { title : "Bygg- och projektledning" },
            ],
        }
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">


                    <Container className="mt-100">
                        <Row className="align-items-center">
                            <Col lg={6} md={6}>
                                <img src={cameraSingle} className="img-fluid" alt="" style={{ borderRadius: 5}} />
                            </Col>

                            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-3">
                                <SectionTitleLeft
                                    title = "Byggekonomi och rådgivning"
                                    desc = "Vi besitter gedigen expertis inom anläggningsekonomi och är stolta över att erbjuda våra kunder specialiserad kunskap och erfarenhet inom området. Med över 12 års erfarenhet har vi framgångsrikt bistått byggherrar, investerare och entreprenörer med omfattande rådgivningstjänster och kostnadsberäkningar genom alla projektets faser. Från inledande förstudier och programhantering till systemhandlingar och slutlig bygghandling, samt till och med genomförandet av färdigställande, har vi varit en pålitlig partner för våra kunder. Genom att kombinera vår expertis med noggrannhet och uppmärksamhet för detaljer, säkerställer vi att våra kunders projekt är ekonomiskt hållbara och framgångsrika."
                                    features = {this.state.features_lines}
                                    class = "mdi-18px h5 mr-2"
                                />
                                    {/* <Link to="#" className="mt-3 text-primary">Få offert<i className="mdi mdi-chevron-right"></i></Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Feature;
