// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

///Import Components
import FeatureBoxSmall from "./feature-box-small";
import SectionTitleLeft from "../../components/Shared/section-title-left";

// Import images
import cameraImg from '../../images/icon/camera.svg';
import speedometer from '../../images/icon/speedometer.svg';
import lowBattery from '../../images/icon/low-battery.svg';
import game from '../../images/icon/game.svg';
import cameraSingle from '../../images/secki/building.jpg';

class Feature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            features_lines : [
                { title : "Fastighetsservice i er bostadsrättförening" },
                { title : "Fastighetsskötsel" },
                { title : "Garantijobb efter slutbesiktning" },
                { title : "VVS" },
                { title : "El" },
                { title : "Måleri" },
                { title : "Snickerijobb in- och utvändigt te.x golvläggning eller nybyggnation av altaner" },
                { title : "”SECKI Fixar” tjänster, enklare jobb te.x montering av lampor, gardiner etc." },
            ],
        }
    }
    render() {
        return (
            <React.Fragment>
                <section className="section mb-2 bg-light">

                    <Container className="mt-100 mt-60">
                        <Row className="align-items-center">

                            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-3">
                                <SectionTitleLeft
                                    title = "Bygg- och fastighetsservice"
                                    desc = "Vi erbjuder våra kunder en omfattande portfölj av professionella bygg- och fastighetsservicetjänster. Med mer än 25 års erfarenhet inom byggbranschen har vi en gedigen kunskap och kompetens inom området. Som erfarna fastighetsskötare förstår vi vikten av att vara noggranna och proaktiva i vårt arbete för att säkerställa att fastigheten är i optimalt skick och att värdet bevaras över tiden. Vårt engagerade team av experter strävar alltid efter att leverera högsta kvalitet och tillfredsställa våra kunders behov och förväntningar."
                                    features = {this.state.features_lines}
                                    class = "mdi-18px h5 mr-2"
                                />
                                    {/* <Link to="#" className="mt-3 text-primary">Få offert<i className="mdi mdi-chevron-right"></i></Link> */}
                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <img src={cameraSingle} className="img-fluid" alt="" style={{ borderRadius: 5}} />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Feature;
