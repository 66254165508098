import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class TeamBox extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.candidates.map((candidate, key) =>
                        <Col md="4" key={key} className="mt-4 pt-2">
                            <div className="text-center rounded bg-white pt-3" name="team" id="teambox">
                                <div className="position-relative">
                                    <img src={candidate.image} className="img-fluid avatar avatar-ex-large rounded-pill" alt=""/>
                                </div>
                                <div className="content pt-3 pb-3">
                                    <h5 className="mb-0">{candidate.name}</h5>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <small className="designation text-muted mt-1">{candidate.designation}</small>
                                        <small className="text-muted mt-1"><a style={{color: '#D22B2B'}} href={`mailto:${candidate.email}`}>{candidate.email}</a></small>
                                        <small className="text-muted mt-1"><a style={{color: '#D22B2B'}} href={`tel:${candidate.phoneNumber}`}>{candidate.phoneNumber}</a></small>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default TeamBox;
