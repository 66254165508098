import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// import images
import kallberga_osmo from '../../images/secki/kallberga_osmo.png';
import kallberga_etapp from '../../images/secki/kallberga_etapp.png';
import lindholmsforbindelsen from '../../images/secki/Lindholmsforbindelsen.png';
import magelungensstrand from '../../images/secki/MagelungensStrand.png';
import vasabron from '../../images/secki/vasabron.png';
import vasterbron from '../../images/secki/vasterbron.png';
import alvsjo from '../../images/secki/alvsjo.png';
import sejo1 from '../../images/secki/sejo1.png';
import sejo2 from '../../images/secki/sejo2.png';

const Projects = () => {
    const [current, setCurrent] = useState(1);

    const ecki = [
        {
            title: 'Källberga – Ösmo, Trafikkoppling',
            period: 'Start 2024 Q1 - Pågår',
            role: 'Entreprenadingenjör',
            description: 'Ombyggnation av gångbana till GC-bana mellan Källberga och Ösmo, total längd ca 800m. Åtgärderna avser flytt av kantstenlinje mot körbanan, nya refuger samt ökade trafiksäkerhetsåtgärder. Uppdraget avser upphandling samt bevakning av genomförande.',
            image: kallberga_osmo,
        },{
            title: 'Källberga, Etapp 2 - 3',
            period: 'Start 2024 - Pågår',
            role: 'Granskningskonsult',
            description: 'Omfattningen avser att stötta projektgruppen i tekniska frågor genom att agera representant för kommunen gentemot exploatör i Källberga exploatering etapp 2-3, löper till 2025.',
            image: kallberga_etapp,
        },{
            title: 'Magelungens Strand',
            period: 'Start 2023 - Slutfört 2024 Q1',
            role: 'Uppdragsledare / Senior kalkylator',
            description: 'Förslaget planerar för ny bebyggelse mellan Farsta och Fagersjö med 900 bostäder och tre förskolor. Längs Magelungens strandpromenad skapas nya mötesplatser samtidigt som Magelungsvägen omvandlas av till stadsgata. Projektet omfattar kostnadsbedömning på allmän platsmark som avser bro, ledningar, gator, landskap, bryggor och strandpromenad.',
            image: magelungensstrand,
        },
        {
          title: 'Lindholmsförbindelsen',
            period: 'Start 2022 - Pågår',
            role: 'TA Kalkyl / Senior kalkylator',
          description: 'Spårvagnstunneln mellan Lindholmen och Linnéplatsen i Göteborg, med en hållplats under Stigbergstorget. I Göta älv blir det en metod av sänktunnel. Projektet omfattar kostnadsbedömning på GFS och därefter “Design-to-cost” fram till systemhandling. Kalkyl på systemhandling beräknas upprättas Q3 2024.',
          image: lindholmsforbindelsen,
        },
        {
          title: 'Vasabron',
            period: 'Start 2022 - Pågår',
            role: 'Uppdragsledare / Senior kalkylator',
            description: 'Vasabron färdigställdes 1878 och består av sju stycken bågbroar som sammanbinder Gamla Stan med Norrmalm. Projektet omfattar kostnadsbedömningar av framtagande förslag/utredningar för att säkra framtida funktion.',
          image: vasabron,
        },
        {
            title: 'Västerbron',
            period: 'Start 2022 - Pågår',
            role: 'Uppdragsledare / Senior kalkylator',
            description: 'Västerbron färdigställdes 1935 och består av två broar, bro över Riddarfjärden och bro över Pålsundet. Projektet omfattar kostnadsbedömningar av framtagande förslag/utredningar för att säkra framtida funktion.',
            image: vasterbron,
        },
        {
            title: 'Tunnelbana till Älvsjö',
            period: 'Start 2022 - Pågår',
            role: 'Uppdragsledare / Senior kalkylator',
            description: 'Fridhemsplan–Älvsjö, cirka åtta kilometer i tunnel. Linjen blir fristående och byggs med modern teknik. Bytespunkter skapas vid stationerna där olika trafikslag knyts samman. Tunnelbanelinjen ligger djupt så hissar ger en smidig resa upp och ner. Uppdraget gäller att ta fram kalkyler från förslagshandling till systemhandling.',
            image: alvsjo,
        },
    ];

    const sejo = [{
      title: 'Fastighetsservice',
        period: 'Start 2019 - Pågår',
      description: 'Garantijobb efter slutbesiktning. Fastighetsskötsel in och utvändigt.',
      image: sejo1,
    },{
      title: 'Fastighetsskötsel utemiljö',
        period: 'Start 2023 - Pågår',
      description: 'Drift och underhållsskötsel av fastigheter i Södra Stockholm. I uppdraget ingår gräsklippning, tömning av sopor, snöröjning etc.',
      image: sejo2,
    }];

    const Card = ({ title, period, role, description, image}) => {
      return (
    <div className="card" style={{ marginTop: 10, marginRight: 10}}>
    <img className="card-img-top" style={{height: 200}} src={image} alt={title}/>
    <div className="card-body">
      <h4 className="card-title" style={{fontSize: 20, fontWeight: 500}}>{title}</h4>
        {period && <p className="card-text font-weight-light" style={{fontSize: 14}}>{period}</p>}
        {role && <p className="card-text" style={{fontSize: 14, fontWeight: 500}}>{role}</p>}
      <p className="card-text font-weight-light" style={{fontSize: 14}}>{description}</p>
    </div>
  </div>
      )
    };

    return (
      <React.Fragment>
      <section className="section bg-light">
          <Container className="mt-100 mt-60">
                    <Row style={{backgroundColor: '#f8f9fc', paddingBottom: 90, width: '--webkit-fill-available'}}>
                        <Col>
                        <h4 className="title mb-4 text-center" style={{ fontSize: '30px'}}>Referensprojekt</h4>
                            <div className="title-heading text-center mt-5 position-relative mb-4" style={{ zIndex: 1 }}>
                                <div>
                                    <button className="btn mt-2 mr-2" onClick={() => setCurrent(1)} style={{backgroundColor: `${current === 1 ? '#D22B2B' : 'white' }`, color: `${current === 1 ? 'white' : '#D22B2B' }`, border: '2px solid #D22B2B'}}>Byggekonomi och rådgivning</button>
                                    <button className="btn mt-2" onClick={() => setCurrent(2)} style={{backgroundColor: `${current === 2 ? '#D22B2B' : 'white' }`, color: `${current === 2 ? 'white' : '#D22B2B' }`, border: '2px solid #D22B2B'}}>Bygg- och fastighetsservice</button>
                                </div>
                            </div>
                            <div className="reference-project-card-div">
                                {current === 1 &&
                                ecki.map((project) => (
                                  <Card key={project.title} {...project}/>
                                ))
                                }
                                {current === 2 &&
                                sejo.map((project) => (
                                  <Card key={project.title} {...project}/>
                                ))
                                }
                            </div>
                        </Col>
                    </Row>
                    </Container>
                    </section>
            </React.Fragment>
        );
};

export default Projects;
