// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

//Import components
import PageBreadcrumb from "../../components/Shared/page-breadcrumb";
import SectionTitle from "../../components/Shared/section-title";
import TeamBox from "../../components/Shared/team-box";

// import images
import team1 from '../../images/team/1.jpg';
import team2 from '../../images/team/2.jpg';
import team3 from '../../images/team/3.jpg';
import team4 from '../../images/team/4.jpg';
import team5 from '../../images/team/5.jpg';
import team6 from '../../images/team/6.jpg';
import team7 from '../../images/team/7.jpg';
import team8 from '../../images/team/8.jpg';
import About from "../Coworking/About";

class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pathItems : [
                //id must required
                { id : 1, name : "Home", link : "/index" },
                { id : 2, name : "Pages", link : "#" },
                { id : 3, name : "Team" },

            ],
            candidates : [
                { id : 1, image : team1, name : "Esmer Kuloglija", designation : "Anläggningsekonom", email: "ekuloglija@secki.se", phoneNumber: "0739195597"
                },
                { id : 2, image : team2, name : "Sead Kuloglija", designation : "Fastighetstekniker/Byggare", email: "sead@secki.se", phoneNumber: "0707516643"
                },
            ]
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);

        var teambox = document.getElementsByName("team");
        for ( var i=0; i<teambox.length; i++){
            teambox[i].classList.remove("rounded");
            teambox[i].classList.remove("bg-white");
            teambox[i].classList.remove("pt-3");
        }
    }
    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }


    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        }
        else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">
                    <Container className="mt-100">
                        <About/>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutUs;
