import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

//Import Images
import img1 from "../../images/secki/samarbetspartners/afry.png";
import img2 from "../../images/secki/samarbetspartners/awl.png";
import img3 from "../../images/secki/samarbetspartners/buildingsweden.svg";
import img4 from "../../images/secki/samarbetspartners/folkbro.png";
import img5 from "../../images/secki/samarbetspartners/funkia.jpeg";
import img6 from "../../images/secki/samarbetspartners/goteborgsstad.png";
import img7 from "../../images/secki/samarbetspartners/kemakta.png";
import img8 from "../../images/secki/samarbetspartners/ledasnyggt.png";
import img9 from "../../images/secki/samarbetspartners/niam.png";
import img10 from "../../images/secki/samarbetspartners/nynaskomun.png";
import img11 from "../../images/secki/samarbetspartners/nynäshamn-bostäder.png";
import img12 from "../../images/secki/samarbetspartners/regionstockholm.png";
import img13 from "../../images/secki/samarbetspartners/stockholmstad.png";

class Partners extends Component {
    render() {
        return (
            <React.Fragment >
                        <Row className="justify-content-center pt-5" id="partners" style={{ filter: "grayscale(100%)" }}>
                            <Col id="partner1" lg="2" md="2" xs="6" className="text-center">
                                <img src={img1} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner2" lg="2" md="2" xs="6" className="text-center">
                                <img src={img2} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner3" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img3} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner4" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img4} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner5" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img5} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                        </Row>
                        <Row className="justify-content-center pt-5" id="partners" style={{ filter: "grayscale(100%)" }}>
                            <Col id="partner6" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img6} className="avatar avatar-ex-sm" alt=""/>
                            </Col>
                            <Col id="partner1" lg="2" md="2" xs="6" className="text-center">
                                <img src={img7} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner2" lg="2" md="2" xs="6" className="text-center">
                                <img src={img8} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner3" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img9} className="avatar avatar-ex-sm" alt=""/>
                            </Col>

                            <Col id="partner4" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                                <img src={img10} className="avatar avatar-ex-sm" alt=""/>
                            </Col>


                        </Row>
                <Row className="justify-content-center pt-5" id="partners" style={{ filter: "grayscale(100%)" }}>
                    <Col id="partner5" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                        <img src={img11} className="avatar avatar-ex-sm" alt=""/>
                    </Col>

                    <Col id="partner6" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                        <img src={img12} className="avatar avatar-ex-sm" alt=""/>
                    </Col>
                    <Col id="partner6" lg="2" md="2" xs="6" className="text-center  mt-4 mt-sm-0">
                        <img src={img13} className="avatar avatar-ex-sm" alt=""/>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Partners;
